import $ from 'jquery';
import '@selectize/selectize';

$(function () {
  // Basic selectize
  $('.selectize').selectize({
    closeAfterSelect: true,
    plugins: ['remove_button'],
  });

  // Autocomplete selectize
  const autocomplete = $('.selectize-autocomplete');
  autocomplete.selectize({
    plugins: ['remove_button'],
    labelField: 'label',
    valueField: 'value',
    searchField: ['value'],
    closeAfterSelect: true,
    normalize: true,
    onType: closeIfEmpty, //if the user deletes all characters, close the modal
    onDropdownOpen: closeIfEmpty, //if the user generates a focus and there is no input, close the modal
    optionGroupRegister: function (optgroup) {
      const capitalised = optgroup.charAt(0).toUpperCase() + optgroup.slice(1);
      const group = {
        label: capitalised,
      };

      group[this.settings.optgroupValueField] = optgroup;

      return group;
    },
    onItemAdd: function () {
      //focus back on the actual input
      $('#region-selectized').trigger("focus");
    },
    optgroupField: 'optgroup',
    score() {
      return () => 1;
    },
    load: function (query, callback) {
      if (query.length < 2) {
        return callback();
      }

      this.clearOptions();

      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
          'action': 'autocomplete_region',
          'q': query,
        },
        success: function (response) {
          callback(response);
        },
      });
    },
  });

  $(document).on('click', '#reset-filters', () => {
    window.location.search = '';
  });
});

$(() => {
  $(document).on('change', '#sort_by', () => {
    const option = $('#sort_by option:selected').val();
    const urlParameters = new URLSearchParams(window.location.search);
    urlParameters.set('sort_by', option);
    window.location.search = urlParameters;
  });
});

function closeIfEmpty() {
  if (this.$control_input.val().length === 0) {
    this.close();
  }
}
